import { default as _91slug_93RyVVoKkmcHMeta } from "/vercel/path0/pages/[slug].vue?macro=true";
import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as _91slug_93_46clientHGCXT5rsnmMeta } from "/vercel/path0/pages/booking/[slug].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as cancell4K40oCkPKMeta } from "/vercel/path0/pages/cancel.vue?macro=true";
import { default as _91ref_932b0rdexGzoMeta } from "/vercel/path0/pages/cart/[ref].vue?macro=true";
import { default as indextHkCpcsk7qMeta } from "/vercel/path0/pages/cart/index.vue?macro=true";
import { default as confirmationBwVU05JSLdMeta } from "/vercel/path0/pages/confirmation.vue?macro=true";
import { default as destinationsjn09LJjFXhMeta } from "/vercel/path0/pages/destinations.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91ref_93gGhXPd6WKpMeta } from "/vercel/path0/pages/participants/[ref].vue?macro=true";
import { default as paymentqSZWcJbf8VMeta } from "/vercel/path0/pages/payment.vue?macro=true";
import { default as index2HhVo2nqwOMeta } from "/vercel/path0/pages/test/index.vue?macro=true";
import { default as _91slug_93KsbhNI1yQEMeta } from "/vercel/path0/pages/tours/[slug].vue?macro=true";
import { default as indexemJKwxSY7PMeta } from "/vercel/path0/pages/tours/index.vue?macro=true";
import { default as _91slug_93A05yCGdaHqMeta } from "/vercel/path0/pages/transfers/[slug].vue?macro=true";
import { default as index0yFqhYR1szMeta } from "/vercel/path0/pages/transportation/index.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/vercel/path0/pages/[slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/vercel/path0/pages/404.vue")
  },
  {
    name: "booking-slug",
    path: "/booking/:slug()",
    component: () => createClientPage(() => import("/vercel/path0/pages/booking/[slug].client.vue"))
  },
  {
    name: "cancel",
    path: "/cancel",
    component: () => import("/vercel/path0/pages/cancel.vue")
  },
  {
    name: "cart-ref",
    path: "/cart/:ref()",
    component: () => import("/vercel/path0/pages/cart/[ref].vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/vercel/path0/pages/cart/index.vue")
  },
  {
    name: "confirmation",
    path: "/confirmation",
    component: () => import("/vercel/path0/pages/confirmation.vue")
  },
  {
    name: "destinations",
    path: "/destinations",
    component: () => import("/vercel/path0/pages/destinations.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "participants-ref",
    path: "/participants/:ref()",
    component: () => import("/vercel/path0/pages/participants/[ref].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/vercel/path0/pages/payment.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test/index.vue")
  },
  {
    name: "tours-slug",
    path: "/tours/:slug()",
    component: () => import("/vercel/path0/pages/tours/[slug].vue")
  },
  {
    name: "tours",
    path: "/tours",
    component: () => import("/vercel/path0/pages/tours/index.vue")
  },
  {
    name: "transfers-slug",
    path: "/transfers/:slug()",
    component: () => import("/vercel/path0/pages/transfers/[slug].vue")
  },
  {
    name: "transportation",
    path: "/transportation",
    component: () => import("/vercel/path0/pages/transportation/index.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/privatetour-details-PPS-PV-PPUREN",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/airport-transfers",
    component: component_45stub5X4Ei38PMg
  }
]