import { generateImages, calculatePricing, imageURLGenerator, getRandomSubset } from '~/utils/objectHelpers';
import { slugify } from '.';
const currateList = [
	{
		img: imageURLGenerator('VAN')[0],
		title: 'Airport Transfers',
	},
	{
		img: imageURLGenerator('VAN')[0],
		title: 'Shared Minivan Transfer',
	},
	{
		img: imageURLGenerator('VAN')[0],
		title: 'Private Transfers',
	},
	{
		img: imageURLGenerator('UR')[0],
		title: 'Private Transfers with Underground River Tour',
	},
	{
		img: imageURLGenerator('UR')[0],
		title: 'Puerto Princesa Underground River Tour',
	},
	{
		img: imageURLGenerator('HB')[0],
		title: 'Tours in Puerto Princesa',
	},
	{
		img: imageURLGenerator('ELN-A')[0],
		title: 'El Nido Joining Boat Tours',
	},
	{
		img: imageURLGenerator('ELN-C')[0],
		title: 'El Nido Private Boat Tours',
	},
	{
		img: imageURLGenerator('ELN-B')[0],
		title: 'El Nido Island Tours and Activities',
	},
];
export const requiredParticipants = (listings, code: string) => {
	const tourTypes = ['PRIVATE_TRANSFER_WITH_TOUR', 'JOINING_TOUR', 'PRIVATE_BOAT_TOUR', 'AIRPORT_TRANSFER', 'TOUR_PACKAGE_LOCAL', 'TOUR_PACKAGE_INTL'];
	const listing = listings.find((listing) => listing.code === code);
	return listing && tourTypes.includes(listing.listing_type);
};

export const slugLibrary = (query: string, StateListings: any) => {
	const data: { img: string; title: string; listings?: any[] } = currateList.find((i) => `/${slugify(i.title)}` === query);
	let listingItems = [
		{ slug: '/shared-minivan-transfer', listings: StateListings.filter((item) => item.code.includes('SV') && item.listing_type === 'SHARED_TRANSFER') },
		{ slug: '/private-transfers', listings: StateListings.filter((item) => item.code.includes('PV') && !item.code.includes('PPUR')) },
		{ slug: '/puerto-princesa-underground-river-tour', listings: StateListings.filter((item) => item.code.includes('PPUR')) },
		{ slug: '/tours-in-puerto-princesa', listings: StateListings.filter((item) => ['JOINING_TOUR', 'PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'PPS') },
		{ slug: '/el-nido-joining-boat-tours', listings: StateListings.filter((item) => ['JOINING_TOUR'].includes(item.listing_type) && item.origin === 'ELN') },
		{ slug: '/el-nido-private-boat-tours', listings: StateListings.filter((item) => ['PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'ELN') },
		{ slug: '/private-transfers-with-underground-river-tour', listings: StateListings.filter((item) => item.code.includes('PV') && item.code.includes('PPUR')) },
		{ slug: '/el-nido-island-tours-and-activities', listings: StateListings.filter((item) => ['JOINING_TOUR', 'PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'ELN') },
	];
	const listingsData = listingItems.find((i) => i.slug === query) || null;
	return {
		listings: listingsData?.listings || [],
		slug: query,
		...data,
	};
};

export const listingsDataBuilder = (listings: any[], rates = {}, reviews = []) => {
	return listings.map((i) => {
		const ratePlan = JSON.parse(JSON.stringify(i.ratePlan));
		const { images, thumbnail } = generateImages(i);

		const ratePlans = Object.entries(rates).reduce((acc, [key, value]) => {
			const currencyRate = parseFloat(value.toString());
			acc[key] = { base: Math.round(parseFloat(ratePlan.base) * currencyRate), inc: parseInt(ratePlan.inc), extra: Math.round(parseFloat(ratePlan.extra) * currencyRate), currency: key };
			return acc;
		}, {});

		return {
			...i,
			ratePlans,
			images,
			thumbnail,
			reviews: getRandomSubset(8, reviews).filter((rev) => rev.reviewRating > 4),
			isBestSeller: ['PV-PPS-ELN', 'PV-PPUR-PPS-ELN', 'PV-ELN-PPS', 'PV-PPUR-ELN-PPS', 'PV-PPS-PBT', 'PV-PBT-ELN', 'SV-PPS-ELN', 'SV-ELN-PPS'].includes(i.code),
		};
	});
};
