import { createVuetify } from 'vuetify';
import type { ThemeDefinition } from 'vuetify';
import { fa } from 'vuetify/iconsets/fa4';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

export default defineNuxtPlugin((nuxtApp) => {
	const vuetify = createVuetify({
		ssr: true,
		display: {
			mobileBreakpoint: 'xs',
		},
		theme: false,
		icons: {
			defaultSet: 'mdi',
			aliases,
			sets: {
				fa,
				mdi,
			},
		},
	});
	nuxtApp.vueApp.use(vuetify);
});
