<template>
	<NuxtLoadingIndicator />
	<NuxtLayout>
		<NuxtPwaManifest />
		<NuxtPage class="mt-15" />
	</NuxtLayout>
</template>
<script setup>
import { useBookingStore } from '~/stores/bookingStore';
import { loadCSSIfNotExists } from '~/utils/seo';
const userCountryHeader = useRequestHeader('x-vercel-ip-country') || 'US';
const userLocaleHeader = useRequestHeader('accept-language')?.split(',')[0] || 'en-US';
const store = useBookingStore();
const cartSize = toRef(() => store.getCartCount);
const currency = toRef(() => store.getUserCurrency);
store.setUserCurrency({
	countryCode: userCountryHeader,
	locale: userLocaleHeader,
});

import { useDisplay } from 'vuetify';
const { mobile } = useDisplay();

store.setIsMobile(mobile.value);

watch(cartSize, async () => {
	if (cartSize.value > 0 && process.client && window) {
		await nextTick();
		window.scrollTo(0, 0);
		setTimeout(() => shaker('cart'), 500);
	}
});

onMounted(async () => {
	loadCSSIfNotExists(['https://cdn.jsdelivr.net/npm/@mdi/font@7.4.47/css/materialdesignicons.min.css', 'https://fonts.googleapis.com/css?family=Material+Icons', 'https://cdn.jsdelivr.net/npm/font-awesome@4.x/css/font-awesome.min.css']);
	if (process.client && window) {
		console.table({
			UserCountry: userCountryHeader,
			UserLocale: userLocaleHeader,
			UserCurrency: currency.value,
			HeaderCountry: useRequestHeader('x-vercel-ip-country'),
			HeaderLocale: useRequestHeader('accept-language'),
		});
	}
});
watch(currency, async () => {
	store.updateCartItemCurrency();
});
</script>
